<template>
  <div class="bg-cercles pad-t-3 pad-b-4">
    <div class="bb-container bb-container--lista">
      <div class="g-camino">
        <div class="g-camino__header">
          <div class="g-camino__ico">
            <img src="../../assets/img/ico/ico-color-gift.svg" alt="" />
          </div>
          <h1 class="g-camino__title">
            {{ $t("guest.kudos.title", {name: list.ownerName}) }}
          </h1>
        </div>
        <div class="g-camino__content">
          <section class="bb-card bb-card--max">
            <div class="card-camino">
              <div class="card-camino__header">
                <div class="card-camino__title">{{ $t("guest.kudos.subtitle") }}</div>
                <p>
                  {{ $t("guest.kudos.description") }}
                </p>
              </div>
              <div class="card-camino__data">
                <div class="card-camino__form">
                  <div class="bb-form-item">
                    <label for="buyerName" class="bb-form-label"> {{ $t("guest.kudos.from") }}* </label>
                    <input
                      id="buyerName"
                      name="buyerName"
                      type="text"
                      class="bb-form"
                      :class="{
                        'bb-form--success': buyerNameIsValid,
                        'bb-form--danger': buyerNameHasError,
                      }"
                      :placeholder="$t('guest.kudos.placeHolder')"
                      v-model="buyerName"
                      @blur="buyerNameBlur"
                    />
                    <div class="bb-form-error" v-show="buyerNameHasError">
                      {{  $t("generic.field_is_required") }}
                    </div>
                  </div>
                  <div class="bb-form-item bb-form-item--inline">
                    <label for="anonymous" class="bb-form-checkbox">
                      <input
                        type="checkbox"
                        id="anonymous"
                        name="anonymous"
                        v-model="anonymous"
                        true-value="yes"
                        false-value="no"
                      />
                      <span class="bb-form-checkbox__inline">{{ $t("guest.kudos.secret") }} </span>
                    </label>
                    <div
                      class="bb-form-checkbox__tooltip"
                      v-tooltip="{
                        html: true,
                        content:
                          $t('guest.kudos.tooltip'),
                        handleResize: false,
                        triggers: ['hover', 'focus', 'click'],
                        // placement: 'auto-end',
                      }"
                    >
                      <img src="../../assets/img/ico/ico-question.svg" alt="Ayuda" />
                    </div>
                  </div>
                  <div class="bb-form-item mar-t-2">
                    <label for="message" class="bb-form-label"> {{ $t("guest.kudos.message") }} </label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="3"
                      class="bb-form-textarea"
                      :placeholder="$t('guest.kudos.messagePlaceHolder')"
                      v-model="message"
                    ></textarea>
                  </div>
                  <div class="bb-form-item mar-t-1">
                    <label for="retailer" class="bb-form-label">
                      {{ $t("guest.kudos.shop") }}*
                    </label>
                    <input
                      id="retailer"
                      name="retailer"
                      v-model.lazy="retailer"
                      type="text"
                      class="bb-form"
                      :placeholder="$t('guest.kudos.shopPlaceHolder')"
                      :class="{
                        'bb-form--success': retailerIsValid,
                        'bb-form--danger': retailerHasError,
                      }"
                      @blur="retailerBlur"
                    />
                    <div class="bb-form-error" v-show="retailerHasError">
                      {{  $t("generic.field_is_required") }}
                    </div>
                  </div>
                  <div class="bb-form-item mar-t-1">
                    <label for="deliveryWay" class="bb-form-label">
                      {{ $t("guest.kudos.delivery") }}*
                    </label>
                    <select id="deliveryWay" class="bb-form-select" v-model="deliveryWay">
                      <option value="" disabled selected hidden class="no-option">
                        {{ $t("guest.kudos.deliveryChoose") }}
                      </option>
                      <option value="1">{{ $t("guest.kudos.deliveryChoosePersonally") }}</option>
                      <option value="2">{{ $t("guest.kudos.deliveryChooseSend") }}</option>
                    </select>
                  </div>
                </div>
                <!-- <div class="card-camino__upload">
                  <p class="fw-bold">¡Ponle cara a tu mensaje!*</p>
                  <div class="upload-photo">
                    <figure
                      class="upload-photo__figure upload-photo__figure--yes"
                      v-show="image !== ''"
                    >
                      <img
                        v-show="image !== ''"
                        :src="image"
                        width="150"
                        height="150"
                        sizes="150px"
                        class="upload-photo__picture"
                        alt=""
                      />
                    </figure>
                    <label v-show="image !== ''" for="image" class="link">
                      Cambiar imagen
                    </label>
                    <figure
                      v-show="image === ''"
                      class="upload-photo__figure upload-photo__figure--no"
                    >
                      <div class="upload-photo__upload">
                        <input
                          type="file"
                          id="image"
                          name="image"
                          @change="previewImage"
                          accept="image/*"
                          hidden
                        />
                        <label
                          for="image"
                          class="button button--sm"
                          v-show="sendImage === 'yes'"
                        >
                          Subir foto
                        </label>
                        <small v-show="sendImage === 'yes'">Máx. 4 MB</small>
                      </div>
                      <img
                        class="upload-photo__picture"
                        src="../../assets/img/covers/upload-no-pic.jpg"
                        alt=""
                      />
                    </figure>
                    <div class="upload-photo__error" v-if="errorMsg">
                      {{ this.errorMsg }}
                    </div>

                    <div class="upload-photo__mobile mar-b-1">
                      <input
                        type="file"
                        id="image"
                        name="image"
                        @change="previewImage"
                        accept="image/*"
                        hidden
                      />
                      <label
                        for="image"
                        class="button button--sm button--block"
                        v-show="sendImage === 'yes'"
                      >
                        Subir foto
                      </label>
                      <small v-show="sendImage === 'yes'">Máx. 4 MB</small>
                    </div>

                    <div class="bb-form-checkbox bb-form-checkbox--inline">
                      <input
                        type="checkbox"
                        id="sendImage"
                        name="sendImage"
                        v-model="sendImage"
                        true-value="no"
                        false-value="yes"
                      />
                      <label for="sendImage" class="bb-form-checkbox__label">
                        No quiero incluir ninguna foto
                      </label>
                    </div>
                    <div class="bb-form-error" v-show="imageHasError">
                      Indicanos si quieres o no incluir una imagen en el mensaje
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="card-camino__actions">
                <loading-button v-if="loading"></loading-button>
                <button
                  v-else
                  class="button button--primary"
                  @click="send()"
                  :disabled="disabled"
                >
                  {{ $t("guest.kudos.button") }}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>

      <!-- SUCCESS MODAL-->
      <transition name="bbFade" @after-enter="showSuccessContent = true">
        <div class="modal" v-show="showSuccess" @click="showSuccessContent = false">
          <transition name="bbUp" @after-leave="finishBuy()">
            <div
              v-show="showSuccessContent"
              class="modal__card modal__card--mini"
              @click.stop=""
            >
              <div class="modal__dialog">
                <div class="modal__header">
                  <div class="modal__back"></div>
                  <div class="modal__title"></div>
                  <div class="modal__close">
                    <img
                      src="../../assets/img/ico/ico-close.svg"
                      alt="Cerrar"
                      @click="showSuccessContent = false"
                    />
                  </div>
                </div>
                <div class="modal__content">
                  <div class="card-success">
                    <div class="card-success__ico">
                      <img
                        src="../../assets/img/ico/ico-success-regalo.svg"
                        alt="¡Genial!"
                      />
                    </div>
                    <div class="card-success__header">{{ $t("guest.kudos.successTitle") }}</div>
                    <div class="card-success__content">
                      {{ $t("guest.kudos.successSubtitle", {name: list.ownerName }) }}
                    </div>
                    <div class="card-success__actions card-success__actions--vertical">
                      <button
                        class="button button--primary button--w50"
                        @click="finishBuy()"
                      >
                        {{ $t("guest.kudos.successButton") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingButton from "../../components/LoadingButton";
import "floating-vue/dist/style.css";
import { VTooltip } from "floating-vue";
import CanGetGuestList from "../../Mixins/CanGetGuestList";

export default {
  name: "ListEnCamino",
  mixins: [CanGetGuestList],
  components: {
    "loading-button": LoadingButton,
  },
  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {
      loading: false,
      buyerName: "",
      buyerNameDirty: false,
      retailerDirty: false,
      anonymous: "no",
      message: "",
      retailer: "",
      deliveryWay: "",
      image: "",
      showSuccess: false,
      showSuccessContent: false,
      errorMsg: "",
      sendImage: "yes",
    };
  },
  metaInfo: {}, // SERGI: DO NOT REMOVE https://github.com/nuxt/vue-meta/issues/391#issuecomment-848823788
  computed: {
    ...mapGetters({
      list: "myList",
      myListLanguage: "myListLanguage",
    }),
    imageHasError() {
      return (
        this.retailer !== "" &&
        this.buyerName !== "" &&
        this.image === "" &&
        this.sendImage === "yes"
      );
    },
    retailerHasError() {
      return this.retailer === "" && this.retailerDirty;
    },
    buyerNameHasError() {
      return this.buyerName === "" && this.buyerNameDirty;
    },
    buyerNameIsValid() {
      return this.buyerName !== "";
    },
    retailerIsValid() {
      return this.retailer !== "";
    },
    disabled() {
      return (
        this.buyerName === "" ||
        this.retailer === "" ||
        this.deliveryWay === ""
      );
    },
  },
  watch: {
    sendImage(newSendImage) {
      if (newSendImage === "no") {
        this.image = "";
      }
    },
  },
  methods: {
    buyerNameBlur() {
      this.buyerNameDirty = true;
    },
    retailerBlur() {
      this.retailerDirty = true;
    },
    previewImage(event) {
      var input = event.target;
      this.errorMsg = "";
      if (input.files && input.files[0]) {
        const maxAllowedSize = 4 * 1024 * 1024;
        if (input.files[0].size > maxAllowedSize) {
          this.errorMsg = "Tamaño máximo 4MB";
          input.value = "";
          return;
        }
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async send() {
      try {
        this.loading = true;
        var body = {
          buyerName: this.buyerName,
          retailer: this.retailer,
          anonymous: this.anonymous === "yes",
          isFreeProduct: this.$route.params.productType === "free",
          deliveryWay: parseInt(this.deliveryWay),
          message: this.message,
        };
        if (this.sendImage === "yes")
          body["image"] = this.image
            .replace("data:image/png;base64,", "")
            .replace("data:image/jpeg;base64,", "");

        await this.$store.dispatch("sendEnCaminoEmail", {
          list: this.list,
          productId: this.$route.params.productId,
          body,
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
        return;
      }
      this.loading = false;

      //get product where id = this.$route.params.productId only if it's a free product. If it's not a free product, the condition should be listProductId = this.$route.params.productId
      var product = null;
      if (this.$route.params.productType === "free") {
        product = this.list.products.find(
          (p) => p.id === this.$route.params.productId && p.isFree
        );
      } else {
        product = this.list.products.find(
          (p) => p.listProductId === this.$route.params.productId && !p.isFree
        );
      }

      window.mixpanel.identify(this.list.userId);
      window.mixpanel.track("Enviar felicitació", {
        Name: this.buyerName,
        Message: this.message,
        "Retailer real": this.retailer,
        "Retailer original": product.retailerName,
        "Gift name": product.name,
        Method:
          parseInt(this.deliveryWay) == 1 ? "els ho portaré jo" : "arribarà per correu",
        "Image included": this.sendImage == "yes",
        Anònim: this.anonymous,
      });

      this.openSuccess();
    },
    openSuccess() {
      this.showSuccess = true;
      document.body.classList.add("modal-open");
    },
    finishBuy() {
      this.showSuccessContent = false;
      document.body.classList.remove("modal-open");
      this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
        this.showSuccessContent = false;
      }
    };
    document.addEventListener("keydown", escapeHandler);
    // eslint-disable-next-line vue/no-deprecated-events-api
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
  mounted() {
    this.$i18n.locale = this.myListLanguage;
  },
};
</script>
